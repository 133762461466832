import fecha from 'fecha'
import { createSelector } from 'reselect'

import { etaInterval, etaIntervalFormatted } from '../misc-selectors/time-range'
import { getIsOrderDelivered } from '../order-status/selectors'
import { getStat } from '../profile/selectors'
import { RootState } from '../reducers'

/**
 * Enums for order status
 * Taken from https://github.com/eaze/api.eazeup.com/blob/master/Source/Green.Common/OrderStatusId.cs
 * To see a list of actually used statuses, go here: https://github.com/eaze/www.eazeup.com/blob/master/src/order/parse/statuses.json
 */
export const orderStatusEnums = {
  STATUS_ORDER_REQUESTED: 1,
  STATUS_ORDER_ACCEPTED: 2,
  STATUS_ORDER_INROUTE: 3,
  STATUS_ORDER_DELIVERED: 4,
  STATUS_ORDER_CANCELED_BY_PATIENT: 5,
  STATUS_ORDER_REMOVED: 6,
  STATUS_ORDER_TIMEOUT: 7,
  STATUS_ORDER_CANCELED_BY_DRIVER: 8,
  STATUS_ORDER_DECLINED: 9,
  STATUS_ORDER_NO_RESPONSE: 10,
  STATUS_ORDER_REROUTE: 11,
  STATUS_ORDER_REROUTE_TIMEOUT: 12,
  STATUS_ORDER_REFUNDED: 13
}

/**
 * List of all the order statuses that are perceived as `canceled` by the user
 * This is used to redirect to /checkout
 * Accordingly with Ben, here we should list all the statuses at https://github.com/eaze/www.eazeup.com/blob/master/src/order/parse/statuses.json where active = false (excluding the `delivered` state)
 * @type {Array}
 */
export const orderStatusCanceled = [
  orderStatusEnums.STATUS_ORDER_CANCELED_BY_PATIENT,
  orderStatusEnums.STATUS_ORDER_REMOVED,
  orderStatusEnums.STATUS_ORDER_TIMEOUT,
  orderStatusEnums.STATUS_ORDER_CANCELED_BY_DRIVER,
  orderStatusEnums.STATUS_ORDER_REROUTE_TIMEOUT
]

/**
 * List of all the order statuses that are perceived as `not canceled` by the user
 */
export const orderStatusNotCanceled = [
  orderStatusEnums.STATUS_ORDER_REQUESTED,
  orderStatusEnums.STATUS_ORDER_ACCEPTED,
  orderStatusEnums.STATUS_ORDER_INROUTE,
  orderStatusEnums.STATUS_ORDER_DELIVERED
]

/**
 * Enums for computed order progress.
 * Currently used by the progress bar in the order delivery status
 * @type {Object}
 */
export const orderProgressEnums = {
  ORDER_RECEIVED: 0, // Order was received and is either within 7 seconds of creation or not assigned to a driver (usually only when no drivers are online)
  ORDER_BEING_PREPARED: 1, // Order is assigned to a driver but is NOT first in queue of deliveries for driver
  ORDER_OUT_FOR_DELIVERY: 2, // Order is assigned and first in queue for driver, but more than MAX_ETA_FOR_ARRIVING seconds away from delivery
  ORDER_IS_ARRIVING: 3, // Order is assigned, first in queue, and within MAX_ETA_FOR_ARRIVING seconds of delivery
  ORDER_DELIVERED: 4 // Order is completed
}

/**
 * When ETA is less than 3 min, then the order is arriving
 */
const MAX_ETA_FOR_ARRIVING = 180

/**
 * Get the order from state
 * @return {object}       Order
 */
export const getOrder = (state: RootState) => state.orders.order

/**
 * Return true if the order is the first in the driver delivery queue
 * @return {bool}
 */
const getIsFirst = createSelector([getOrder], (order) => order.delivery.indexInDeliveryQueue === 0)

/**
 * Get the status from order
 */
const getOrderStatus = createSelector([getOrder], (order) => order.status)

/**
 * Get the ETA from order
 */
export const getEta = createSelector([getOrder], (order) => {
  const {
    delivery: { estimatedDeliveryDateTime }
  } = order

  // set the estimate floor to 3 minutes
  const ESTIMATE_SECONDS_FLOOR = 180
  // we need to ensure we append a Z to the end of the datetime string the backend gives us otherwise js assumes local time not UTC
  const isoString = `${estimatedDeliveryDateTime}Z`
  const timeDeltaMilliseconds = Date.parse(isoString) - Date.now()
  const timeDeltaSeconds = timeDeltaMilliseconds / 1000

  // if the original or new estimate deltas are below estimate floor (right now 3 minutes) then always return the floor
  if (timeDeltaSeconds <= ESTIMATE_SECONDS_FLOOR) {
    return ESTIMATE_SECONDS_FLOOR
  }

  return timeDeltaSeconds
})

/**
 * Get if order has been assigned to a driver
 */
const getHasBeenAssignedToDriver = (state: RootState) => state.orders.hasBeenAssignedToDriver

/**
 * Indicate if the result data has ever loaded before
 * @return {bool}
 */
export const getHasResultEverLoaded = (state: RootState) => state.orders.firstTimeLoaded

/**
 * Used to determine whether this user has ever placed an order before
 */
export const lastRequestedOrderAt = createSelector([getStat], (stat) => stat.lastRequestedOrderAt)

/**
 * Get the order progress combining multiple parameters into a user friendly status
 * Currently used by the progress bar in the order delivery status
 * @return {Object<orderProgressEnums>}
 */

export const getOrderProgress = createSelector(
  [getOrderStatus, getIsFirst, getEta, getHasBeenAssignedToDriver],
  (orderStatus, isFirst, eta, hasBeenAssignedToDriver) => {
    // Statuses are arranged in reverse chronological order, from delivered -> placed

    // Order was delivered
    if (orderStatus === orderStatusEnums.STATUS_ORDER_DELIVERED) {
      return orderProgressEnums.ORDER_DELIVERED
    }

    // Order is arriving: first in line and eta < 2 min
    if (isFirst && eta < MAX_ETA_FOR_ARRIVING) {
      return orderProgressEnums.ORDER_IS_ARRIVING
    }

    // Order assigned to a driver and the customer is first in line
    if (isFirst) {
      return orderProgressEnums.ORDER_OUT_FOR_DELIVERY
    }

    // Order accepted but not first in driver's queue
    if (orderStatus === orderStatusEnums.STATUS_ORDER_ACCEPTED) {
      return orderProgressEnums.ORDER_BEING_PREPARED
    }

    // Order placed but not accepted yet
    if (orderStatus === orderStatusEnums.STATUS_ORDER_REQUESTED) {
      return orderProgressEnums.ORDER_RECEIVED
    }

    // Order is new (< 7 seconds old) and assumed to have not been assigned
    if (!hasBeenAssignedToDriver) {
      return orderProgressEnums.ORDER_RECEIVED
    }

    return -1
  }
)

/**
 * Determine if order was canceled by the driver
 * @return {boolen}
 */
export const getOrderCanceledByDriver = createSelector(
  [getOrderStatus],
  (orderStatus) => orderStatus === orderStatusEnums.STATUS_ORDER_CANCELED_BY_DRIVER
)

/**
 * It represents if the order status is perceived as `canceled` by the user
 * @return {boolean}
 */
export const getOrderCanceled = createSelector([getOrderStatus], (orderStatus) =>
  orderStatusCanceled.includes(orderStatus)
)

/* Return range for time estimate */
const getEtaInterval = createSelector([getEta, getIsFirst], (eta, isFirst) => {
  return etaInterval(eta, isFirst)
})

/**
 * Format the eta interval based on the presence of a high parameter
 * @return {string} the formatted interval in minutes
 */
export const getEtaIntervalFormatted = createSelector([getEtaInterval], (interval) => {
  return etaIntervalFormatted(interval)
})

/**
 * Format the `delivered at` time
 * @return {string}        Formatted time
 */
export const getDeliveredAtFormatted = createSelector([getOrder], (order) => {
  /* Specify 'Z' at the end of the timezone to make sure FF + Chrome both treat it as UTC time */
  const deliveredAt = order.deliveredAt ? `${order.deliveredAt}Z` : null
  if (deliveredAt) {
    return fecha.format(new Date(Date.parse(deliveredAt)), 'h:mm a')
  }
  return null
})

/**
 * Get rating submitted within current session
 */
const getRating = (state: RootState) => state.orders.feedback.rating

/**
 * Get order rating, either from current session or existing rating on order.
 * This will let us know if the order is submitted through order-rating or
 * was already rated.
 */
export const getOrderRating = createSelector([getOrder, getRating], (order, rating) => {
  return order.customerRating || rating || 0
})

/**
 * Get whether feedback was submitted in current session
 */
const getIsFeedbackSubmitted = (state: RootState) => state.orders.feedback.submitted

/**
 * Get whether feedback has already been submitted
 */
const getHasBeenRated = createSelector([getOrder], (order) => {
  return order.customerRating !== null
})

/**
 * Get whether feedback was submitted in current session or already on the order
 */
export const getIsRated = createSelector([getIsFeedbackSubmitted, getHasBeenRated], (isRated, hasBeenRated) => {
  return isRated || hasBeenRated
})

/**
 * Compute whether or not we should show the order feedback form.
 * Show if the order is completed or if the order feedback has already been
 * submitted.
 * @return {boolean} whether we should be showing the order feedback form
 */
export const getShouldShowFeedback = createSelector(
  [getHasBeenRated, getIsOrderDelivered],
  (hasBeenRated, isOrderDelivered) => {
    return hasBeenRated || isOrderDelivered
  }
)

export const getCardChargeDescriptor = createSelector([getOrder], (order) => order.chargeDescriptor)

export const getOrderDeliveryType = createSelector([getOrder], (order) => order.deliveryType)

export const getOrderDelivery = createSelector([getOrder], (order) => order.delivery)

export const getDriverTip = createSelector([getOrder], (order) => order.driverTip)

export const getOrderProducts = createSelector([getOrder], (order) => order.products)

export const getOrderPaymentMethod = createSelector([getOrder], (order) => order.paymentMethod)

export const getOrderPriceInfo = createSelector([getOrder], (order) => order.priceInfo)

export const getTaxLineItems = createSelector([getOrderPriceInfo], (priceInfo) => priceInfo.taxLineItems)

export const getChargeAmount = createSelector(
  [getOrderPriceInfo],
  (priceInfo) => priceInfo.chargeAmount // is there a difference between state.orders.order.chargeAmount and state.orders.order.priceInfo.chargeAmount??
)
export const getOrderDeliveryFee = createSelector([getOrderPriceInfo], (priceInfo) => priceInfo.deliveryFee)
export const getOrderCardDetail = createSelector([getOrder], (order) => order.debitCardDetail)

export const getOrderCreatedAt = createSelector([getOrder], (order) => order.createdAt)

export const getOrderPromoName = createSelector([getOrder], (order) => order.promoName)
